<template>
  <a :href="mediaLinkMapping[type]"
     target="_blank"
     rel="noopener"
     :class="['footer-social-link', `footer-social-link--${type}`]"
     :aria-label="`Check out our ${type} profile`">
    <span class="footer-social-link__icon-wrapper"><i :class="mediaIconClassMapping[type]"/></span>
  </a>
</template>

<script>
  export default {
    name: 'FooterSocialLink',
    props: {
      type: {
        type: String,
        required: true,
        validator: (type) => ['pinterest', 'facebook', 'instagram', 'linkedin'].includes(type)
      }
    },
    data () {
      return {
        mediaIconClassMapping: {
          pinterest: 'fab fa-pinterest-square',
          facebook: 'fab fa-facebook-square',
          instagram: 'fab fa-instagram-square',
          linkedin: 'fab fa-linkedin'
        },
        mediaLinkMapping: {
          pinterest: 'http://pinterest.com/scoutandnimble/',
          facebook: 'https://facebook.com/scoutandnimble',
          instagram: 'http://instagram.com/scoutandnimble',
          linkedin: 'https://www.linkedin.com/company/scout-&-nimble/',
        }
      }
    }

  }
</script>

<style lang="scss" scoped>
  @import '@/assets/stylesheets/variables.scss';

  .footer-social-link {
    font-size: 3.4rem;
    color: $color-white;

    &:hover {
      color: $silver;
    }
  }
</style>
