<template>

  <div class="footer-links-wrapper">

    <div class="footer-links" :style="gridTemplateColumnsStyle">
      <div class="links" v-for="menuItem in menuItems" :key="menuItem.id">
        <div class="name">{{ menuItem.name }}</div>
        <ul>
          <li v-for="subMenuItem in menuItem.children" :key="subMenuItem.id">
            <navigation-item-link :menu-item="subMenuItem"/>
          </li>
        </ul>
      </div>
    </div>

  </div>

</template>

<script>
import WindowSizeMixin from '@/mixins/windowSize'
import NavigationItemLink from "@/components/navigation/NavigationItemLink";

export default {
  name: 'footer-links',
  components: {NavigationItemLink},
  mixins: [WindowSizeMixin],
  data() {
    return {
      categories: [
        {label: 'Living', name: 'living'},
        {label: 'Dining', name: 'kitchen-and-dining'},
        {label: 'Office', name: 'office'},
        {label: 'Bed & Bath', name: 'bed-and-bath'},
        {label: 'Home Decor', name: 'home-accents-and-decor'},
        {label: 'Lighting', name: 'lighting'},
        {label: 'Rugs', name: 'rugs'},
        {label: 'Outdoor', name: 'outdoor'}
      ]
    }
  },
  computed: {
    menu() {
      const menuHandle = this.isMobile ? 'footer_mobile' : 'footer_desktop'
      return this.$store.getters['menu/getMenu'](menuHandle)
    },
    menuItems() {
      return (this.menu && this.menu.menu_items) || []
    },
    gridTemplateColumnsStyle() {
      return {
        'grid-template-columns': `repeat(${Math.min(this.menuItems.length, 4)}, 1fr)`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/variables.scss';

$sections: about, support, legal, shop;

.footer-links-wrapper {
  .footer-links {
    display: grid;
    grid-row-gap: 60px;
    grid-column-gap: 30px;
    margin: 0;
    padding: 0;
    grid-template-columns: repeat(4, 1fr);

    .links {
      .name {
        font-family: 'MotivaSans';
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.2rem;
        text-align: left;
        text-transform: uppercase;
        color: $color-white;
        margin-bottom: 8px;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          padding: 0;
          line-height: 1.4px;
          font-family: 'MotivaSans';

          a {
            color: $color-white;

            &:hover {
              border-bottom: 1px solid $color-white;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $tablet-max) {
    .footer-links {
      justify-content: center !important;
    }
  }

  @media only screen and (max-width: $mobile-max) {
    .footer-links {
      grid-template-columns: 1fr 1fr !important;
      grid-row-gap: 40px !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .footer-links {
      grid-template-columns: 150px auto !important;
      grid-column-gap: 36px !important;
    }
  }
}
</style>
