<template>

  <div class="subscription-wrapper">
    <h3 class="information-header">JOIN OUR EMAIL LIST FOR A SPECIAL OFFER.*</h3>
    <p class="information">PLUS, be the first to hear about promotions, new arrivals, and design trends.</p>

    <div class="email">
      <input v-model="email" id="email" type="text" aria-label="email" placeholder="Enter your email address">
      <div class="btn-submit btn large inverted" role="button" @click="addEmailToNewsletter">SIGN ME UP!</div>
    </div>

    <div class="reference">
      *Offer can be applied to non-sale items only. Other exclusions may apply.
    </div>

    <snackbar ref="snackbar" :multiple="false" :holdTime="650500"/>
  </div>

</template>

<script>
import ApiUser from '@/api/User'
import Snackbar from "@/components/Snackbar"
import CordialMixin from '@/mixins/cordial'

export default {
  name: 'footer-subscription',
  components: {
    Snackbar
  },
  mixins: [CordialMixin],
  data() {
    return {
      email: ''
    }
  },
  methods: {
    addEmailToNewsletter() {
      if (this.email) {
        this.cordialAddUser(this.email)
        ApiUser.addEmailToNewsletter(this.$axios, {email: this.email})
            .then(response => {
              this.email = ''
              this.$refs.snackbar['open']('Thank you!\n' + 'Your email has been successfully registered')
            })
            .catch(error => {
              this.$refs.snackbar['open']("Email address not found. Please check and try again.")
            })
      }
    },
    cordialAddUser(email) {
      let cartData = this.$store.getters['order/getCartCordial']
      this.identifyCordialUser(this.email, cartData)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/variables.scss';

.subscription-wrapper {
  font-size: 1.6rem;
  font-weight: 400;

  .information-header {
    font-style: italic;
    color: $color-white;
    margin: 0;
  }

  .information {
    color: $color-white;
    margin: 25px 0;
  }

  .reference {
    font-size: 1.4rem;
    font-style: italic;
    color: $color-gray-verylight;
  }

  .email {
    display: grid;
    grid-template-columns: 250px 130px;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 15px;

    input {
      padding: 14px 16px;
      font-size: 1.6rem;
      background: $color-white;

      &::placeholder {
        font-family: 'MotivaSans';
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        color: $color-gray-dark;
        text-transform: none;
      }
    }

    .btn-submit {
      font-size: 1.5rem;
      font-weight: normal;
      padding: 12px 15px;
    }
  }

  @media screen and (max-width: 1500px) {
    .information {
      margin: 20px 0;
    }

    .email {
      grid-template-columns: 1fr 130px;

      input {
        padding: 14px 16px;
        font-size: 1.6rem;
        background: $color-white;

        &::placeholder {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .email {
      grid-template-columns: 1fr;
      grid-row-gap: 15px;
    }
  }
}
</style>
