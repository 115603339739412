<template>
  <div class="footer-contact">

    <a href="tel:+1-833-857-2062"
       class="footer-contact__line footer-contact__line--clickable">
      <i class="fas fa-phone"/>
      <span>+1-833-857-2062</span>
    </a>

    <a href="mailto:support@scoutandnimble.com"
       class="footer-contact__line footer-contact__line--clickable">
      <i class="fas fa-envelope"/>
      <span>support@scoutandnimble.com</span>
    </a>

    <div class="footer-contact__line">
      <i class="fas fa-clock"/>
      <span>Monday - Friday, 9 am - 5 pm CT</span>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'FooterContact'
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/stylesheets/variables.scss';

  .footer-contact {
    display: grid;
    grid-row-gap: 15px;
    color: $color-white;
    font-size: 1.4rem;
    text-align: center;

    &__line {
      margin: 0 auto;
      display: flex;
      align-items: center;
      white-space: nowrap;

      &--clickable {
        color: $color-white;

        &:hover {
          color: $silver;
        }

        span {
          text-decoration: underline;
        }
      }

      i {
        margin-right: 10px;
        font-size: 2.4rem;
      }
    }
  }

  @media (min-width: 1361px) {
    .footer-contact {
      grid-row-gap: 22px;
      display: flex;
      align-items: center;
      justify-self: end;

      &__line {
        margin-left: 0;

        &:not(:last-child) {
          padding-right: 0;
        }
      }

      &__line:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @media (min-width: 1400px) {
    .footer-contact {
      &__line {
        &:not(:last-child) {
          padding-right: 20px;
        }
      }
    }
  }
</style>
