export default {
    data () {
        return {
            windowWidth: process.client ? window.innerWidth : 480,
            tabletMax: 1024
        }
    },
    computed: {
        isMobileOrTablet () {
            if (process.server) {
                return
            }

            return this.windowWidth <= this.tabletMax
        },
        isMobile () {
            if (process.server) {
                return true
            }
            return this.windowWidth < 768
        },
        isTablet () {
            if (process.server) {
                return true
            }
            return this.windowWidth <= this.tabletMax && this.windowWidth > 768
        }
    },
    mounted () {
        if (process.client) {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
                this.onResize()
            })
        }
    },
    beforeDestroy () {
        if (process.client) {
            window.removeEventListener('resize', this.onResize);
        }
    },
    methods: {
        onResize () {
            this.windowWidth = window.innerWidth
        }
    }
}
