import { render, staticRenderFns } from "./FooterSocialLink.vue?vue&type=template&id=8786359c&scoped=true&"
import script from "./FooterSocialLink.vue?vue&type=script&lang=js&"
export * from "./FooterSocialLink.vue?vue&type=script&lang=js&"
import style0 from "./FooterSocialLink.vue?vue&type=style&index=0&id=8786359c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8786359c",
  null
  
)

export default component.exports