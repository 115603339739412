<template>
    <div class="social-section">
        <ul>
            <li><footer-social-link type="instagram"/></li>

            <li><footer-social-link type="pinterest"/></li>

            <li><footer-social-link type="facebook"/></li>

            <li><footer-social-link type="linkedin"/></li>

<!--            <li>-->
<!--                <a href="http://twitter.com/scoutandnimble" target="_blank" rel="noopener">-->
<!--                    <lazy-img :lazy-src="getImgUrl('social/twitter.svg')"></lazy-img>-->
<!--                </a>-->
<!--            </li>-->

<!--            <li>-->
<!--                <a href="https://plus.google.com/+JesseBodine/posts" target="_blank" rel="noopener">-->
<!--                    <lazy-img :lazy-src="getImgUrl('social/googleplus.svg')"></lazy-img>-->
<!--                </a>-->
<!--            </li>-->
        </ul>
    </div>
</template>

<script>
    // import LazyImg from '@/../shared/components/LazyImg'
    import FooterSocialLink from '@/components/FooterSocialLink'

    export default {
        name: 'footer-social-section',
        components: {
            // LazyImg,
            FooterSocialLink
        },
       methods: {
            getImgUrl(image) {
                return require('@/assets/images/' + image)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/stylesheets/variables.scss';

    .social-section{
        display: flex;
        align-items: center;

        ul{
            list-style: none;
            display: flex;
            padding: 0;
            text-align: center;
            align-items: center;
            column-gap: 1.6rem;

            li{
                display: inline-block;
                padding: 0;

                img{
                    width: 30px;
                }
            }
        }

        @media (min-width: 1361px) {
            ul {
                text-align: right !important;
            }
        }
    }
</style>
